.button {
    padding: 9px 13px;
    border-radius: 50px;
    border: solid 1px gray;
    cursor: pointer;
    font-size: 12px;
    background-color: white;
    margin: 8px;
    

}

.button:hover {
    background-color: #3E6947;
    color: white;
    border: solid 1px #3E6947;
}

.buttons {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}